const styles = {
  global: () => ({
    'html, body': {
      fontSize: 'sm',
      color: 'black',
      background: 'white'
    }
  })
};

export default styles;

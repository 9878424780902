import getBaseUrl from '@app/helpers/getBaseUrl';
import type { Project } from '@app/pages/api/listProjects';
import { useQuery } from '@tanstack/react-query';

export const fetchProjects = async () => {
  const res = await fetch(`${getBaseUrl()}/api/listProjects`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'GET'
  }).then(res => res.json());

  return res ?? [];
};
export const useProjects = () => {
  const res = useQuery<Project[]>({
    queryKey: ['projects'],
    queryFn: fetchProjects
  });

  return res;
};

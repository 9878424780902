import { ModeTestnet, Mode } from '@thirdweb-dev/chains';
import { config } from '@app/config';

export enum ModeEnvs {
  MAINNET = 'MAINNET',
  TESTNET = 'TESTNET'
}

export const getActiveChain = () => {
  switch (config.modeEnv) {
    case ModeEnvs.TESTNET: {
      return ModeTestnet;
    }
    case ModeEnvs.MAINNET:
    default: {
      return Mode;
    }
  }
};

import getBaseUrl from '@app/helpers/getBaseUrl';
import { User } from '@app/pages/profile';
import { useQuery } from '@tanstack/react-query';

export const fetchUser = async () => {
  const res = await fetch(`${getBaseUrl()}/api/profile`, {
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    method: 'GET'
  }).then(res => res.json());

  return res;
};

export const fetchUserRegisterations = async () => {
  try {
    const response = await fetch(`${getBaseUrl()}/api/userRegisterations`, {
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      method: 'GET'
    });

    if (!response.ok) {
      console.error(
        'Fetch user registrations failed with status:',
        response.status
      );

      return [];
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Failed to fetch user registrations:', error);

    return [];
  }
};

export const createUser = async (publicKey: string) => {
  const res = await fetch(`${getBaseUrl()}/api/profile`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      publicKey
    })
  }).then(res => res.json());

  return res;
};

export const updateUser = async ({
  publicKey,
  user
}: {
  publicKey: string;
  user: Partial<User>;
}) => {
  const res = await fetch(`${getBaseUrl()}/api/profile`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      publicKey,
      user
    })
  }).then(res => res.json());

  return res;
};

export const useUser = (publicKey: string | undefined) => {
  const res = useQuery<User>({
    queryKey: ['user', publicKey],
    queryFn: fetchUser,
    enabled: !!publicKey
  });

  return res;
};

export const useUserRegisterations = (publicKey: string | undefined) => {
  const res = useQuery({
    queryKey: ['userRegisterations'],
    queryFn: fetchUserRegisterations,
    enabled: !!publicKey
  });

  return res ?? [];
};

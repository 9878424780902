import { useQuery } from '@tanstack/react-query';

const SYNAPS_BASE_URL = 'https://api.synaps.io/v4';

const apiKey = process.env.NEXT_PUBLIC_SYNAPS_API_KEY as string;

type SubmissionStatus =
  | 'APPROVED'
  | 'REJECTED'
  | 'RESUBMISSION_REQUIRED'
  | 'SUBMISSION_REQUIRED'
  | 'PENDING_VERIFICATION';

type SynapsStatus = {
  app: {
    name: string;
    id: string;
  };
  session: {
    id: string;
    alias: string;
    status: SubmissionStatus;
    sandbox: boolean;
    steps: [
      {
        id: string;
        status: SubmissionStatus;
        type: 'LIVENESS';
      },
      {
        id: string;
        status: SubmissionStatus;
        type: 'ID_DOCUMENT';
      }
    ];
  };
};

export const createSynapsID = async (publicKey: string) => {
  const { session_id } = await fetch(`${SYNAPS_BASE_URL}/session/init`, {
    method: 'POST',
    headers: {
      'Api-Key': apiKey
    },
    body: JSON.stringify({ alias: publicKey })
  }).then(res => {
    return res.json() as Promise<{ sandbox: boolean; session_id: string }>;
  });

  return session_id;
};

export const fetchSynapsStatus = async (synapsID: string | undefined) => {
  const res = await fetch(`${SYNAPS_BASE_URL}/individual/session/${synapsID}`, {
    method: 'GET',
    headers: {
      'Api-Key': apiKey
    }
  }).then(res => res.json() as Promise<SynapsStatus>);

  return res;
};

export const useSynapsStatus = (synapsID: string | undefined) => {
  const res = useQuery<SynapsStatus>({
    queryKey: ['synapsID', synapsID],
    queryFn: () => fetchSynapsStatus(synapsID),
    enabled: !!synapsID
  });

  return res;
};

import { extendTheme } from '@chakra-ui/react';
import { Rubik } from 'next/font/google';

const rubik = Rubik({
  subsets: ['latin'],
  variable: '--font-rubik'
});

import colors from './colors';
import styles from './styles';

// https://tailwindcss.com/docs/container
export const maxWidths = {
  base: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px'
};

export default extendTheme({
  styles,
  colors,
  fonts: {
    heading: rubik.style.fontFamily,
    body: rubik.style.fontFamily,
    rubik: rubik.style.fontFamily
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false
  }
});

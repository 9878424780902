import React, { useEffect, useState, useCallback, useContext } from 'react';
import type { Project } from '@app/pages/api/listProjects';
import { useProjects } from '@app/api/projects';

export interface Projects {
  projects: { [key: string]: Project };
}

export const ProjectsContext = React.createContext<Projects>({
  projects: {}
});

export const ProjectsProvider = ({ children = null as any }) => {
  const { data: projects = {} } = useProjects();

  return (
    <ProjectsContext.Provider value={{ projects }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjectsContext = () => {
  const context = useContext(ProjectsContext);

  return context;
};

import React, { useContext } from 'react';
import { useAddress } from '@thirdweb-dev/react';
import { useUser, useUserRegisterations } from '@app/api/profile';
import { useSynapsStatus } from '@app/api/synaps';
import type { Project } from '@app/pages/api/listProjects';
import { User } from '@app/pages/profile';
import type { ProjectRegistration } from '@app/pages/api/userRegisterations';

export type SubmissionStatus =
  | 'APPROVED'
  | 'REJECTED'
  | 'RESUBMISSION_REQUIRED'
  | 'SUBMISSION_REQUIRED'
  | 'PENDING_VERIFICATION'
  | undefined;

type SynapsStatus = {
  app: {
    name: string;
    id: string;
  };
  session: {
    id: string;
    alias: string;
    status: SubmissionStatus;
    sandbox: boolean;
    steps: {
      id: string;
      status: SubmissionStatus;
      type: 'LIVENESS' | 'ID_DOCUMENT';
    }[];
  };
};

interface UserInfo {
  user: User | undefined;
  userRegisterations: ProjectRegistration[];
  userIsLoading: boolean;
  synapse: {
    synapsStatus: SynapsStatus | undefined;
    isLoading: boolean | undefined | string;
    refetchUser: () => void;
    refetchSynaps: () => void;
  };
}

export const UserContext = React.createContext<UserInfo>({
  user: undefined,
  userRegisterations: [],
  userIsLoading: true,
  synapse: {
    synapsStatus: undefined,
    isLoading: true,
    refetchUser: () => {},
    refetchSynaps: () => {}
  }
});

interface UserInfoProviderProps {
  children: React.ReactNode;
}

export const UserInfoProvider: React.FC<UserInfoProviderProps> = ({
  children = null as any
}) => {
  const address = useAddress();
  const {
    data,
    isLoading: userLoading,
    refetch: refetchUser
  } = useUser(address);
  const { data: userRegisterations } = useUserRegisterations(address);
  const {
    data: synapsStatus,
    isLoading: synapsLoading,
    refetch: refetchSynaps
  } = useSynapsStatus(data?.synapsID);

  const synapseIsLoading = userLoading || (data?.synapsID && synapsLoading);

  return (
    <UserContext.Provider
      value={{
        user: data,
        userRegisterations,
        userIsLoading: userLoading,
        synapse: {
          synapsStatus,
          isLoading: synapseIsLoading,
          refetchUser,
          refetchSynaps
        }
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserDetailsContext = () => {
  const context = useContext(UserContext);

  return context;
};

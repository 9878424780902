import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import {
  ThirdwebProvider,
  coinbaseWallet,
  metamaskWallet,
  rainbowWallet,
  walletConnect
} from '@thirdweb-dev/react';
import { Analytics } from '@vercel/analytics/react';
import React from 'react';

import { config } from '@app/config';
import theme from '@app/theme';

import { ProjectsProvider } from '@app/context/ProjectsContext';
import { UserInfoProvider } from '@app/context/UserContext';
import { getActiveChain } from '@app/constants/chains';
import getBaseUrl from '@app/helpers/getBaseUrl';

interface AppContextWrapperProps {
  children: React.ReactNode;
  query: { country: string; city: string; region: string };
}

const activeChain = getActiveChain();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const AppContextWrapper = ({ children }: AppContextWrapperProps) => {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ProjectsProvider>
          <ThirdwebProvider
            activeChain={activeChain}
            clientId={config.thirdwebClientId}
            supportedChains={[activeChain]}
            authConfig={{
              domain: getBaseUrl() || '',
              authUrl: '/api/auth'
            }}
            supportedWallets={[
              coinbaseWallet(),
              metamaskWallet(),
              rainbowWallet(),
              walletConnect()
            ]}
          >
            <UserInfoProvider>
              {children}
              <Analytics />
            </UserInfoProvider>
          </ThirdwebProvider>
        </ProjectsProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default AppContextWrapper;
